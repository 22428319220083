import { TDateFormats } from '@timelog/ui-library';
import { format } from 'date-fns';
import { DateTime } from 'luxon';
import { Language } from 'src/apis/types/userSettingsAPI';
import { enGB, da, de, fi, nb, nl, ru, sv, fr } from 'date-fns/locale';

export const getYearMonthDateFromDateString = (date?: string) =>
  DateTime.fromISO(date || DateTime.now().toISO())
    .toISO()
    .split('T')[0];

export const getFirstDayOfWeek = (d?: DateTime) => {
  const date = d || DateTime.now();

  return date.startOf('week').toISODate();
};

export const getDateOneWeekFromStartOfWeek = (d?: DateTime) => {
  const startOfWeek = getFirstDayOfWeek(d || DateTime.now());

  return getYearMonthDateFromDateString(
    DateTime.fromISO(startOfWeek).plus({ days: 6 }).toISODate(),
  );
};

export const getDateTwoWeeksFromStartOfWeek = (d?: DateTime) => {
  const startOfWeek = getFirstDayOfWeek(d || DateTime.now());

  return getYearMonthDateFromDateString(
    DateTime.fromISO(startOfWeek).plus({ days: 13 }).toISODate(),
  );
};

export const getDateThirtyDaysFromNow = (d?: string) => {
  const today = d || DateTime.now().toISO();

  return getYearMonthDateFromDateString(DateTime.fromISO(today).plus({ days: 29 }).toISODate());
};

export const convertDateToLocaleString = (date: Date, locale: string) => {
  const dateString = date.toLocaleDateString(locale, {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  });

  // In TimeLog, da-DK format follows dd-mm-yyyy instead of the standard dd.mm.yyyy.
  if (locale !== 'da-DK') {
    return dateString;
  }

  return dateString.replace(/\./g, '-');
};

export type TShortString = Language['shortString'];

export const localeDateFormat = (lang: TShortString): TDateFormats => {
  let locale: TDateFormats;

  switch (lang) {
    case 'da' || 'nl': {
      locale = 'dd-MM-yyyy';
      break;
    }

    case 'en': {
      locale = 'dd/MM/yyyy';
      break;
    }

    case 'ru' || 'no' || 'de' || 'fi': {
      locale = 'dd.MM.yyyy';
      break;
    }

    case 'de': {
      locale = 'dd.MM.yyyy';
      break;
    }

    case 'sv': {
      locale = 'yyyy-MM-dd';
      break;
    }

    default: {
      locale = 'dd-MM-yyyy';
    }
  }

  return locale;
};

export const getLanguageFromSiteLocale = (siteLocale: string = 'da-DK'): TShortString => {
  const siteLanguage =
    siteLocale && siteLocale.length > 2 ? siteLocale.substring(0, siteLocale.indexOf('-')) : 'da';

  return siteLanguage as TShortString;
};
type LanguageShortString = Language['shortString'] | undefined;
export const getDateLocaleFromUserLanguage = (userLanguage: LanguageShortString): Locale => {
  switch (userLanguage) {
    case 'da':
      return da;
    case 'de':
      return de;
    case 'fi':
      return fi;
    case 'fr':
      return fr;
    case 'nb':
      return nb;
    case 'nl':
      return nl;
    case 'ru':
      return ru;
    case 'sv':
      return sv;
    default:
      return enGB;
  }
};

export const getDateStringFromSiteLocale = (date: Date, siteLocale: string): string => {
  const siteLanguage = getLanguageFromSiteLocale(siteLocale);
  const locale = localeDateFormat(siteLanguage);

  return format(date, locale);
};

export const getDateWithZeroOffsetFromDateString = (dateString: string | undefined | null) => {
  // expected date string is yyyyMMddT00:00:00
  let tmpDateStr = '';

  if (!dateString || dateString === undefined || dateString === null) {
    return new Date();
  }

  tmpDateStr = dateString.substring(0, dateString.indexOf('T'));

  const [year, month, day] = tmpDateStr.split('-');

  const monthString = month.length === 1 ? `0${month}` : month;
  const dayString = day.length === 1 ? `0${day}` : day;

  const date = new Date(`${year}-${monthString}-${dayString}T00:00:00`);

  return date;
};
